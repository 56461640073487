import { ComputedRef, InjectionKey, Ref } from 'vue';

export const ProvidedIsSidebarOpened: InjectionKey<Ref<boolean>> = Symbol();

export const ProvidedExportLimitReached: InjectionKey<ComputedRef<boolean>> =
  Symbol();

export const ProvidedIsSidebarCollapsed: InjectionKey<Ref<boolean>> = Symbol();

export const ProvidedOnboardingStepsLeft: InjectionKey<Ref<number>> = Symbol();

export const ProvidedHasCompletedOnboarding: InjectionKey<Ref<boolean>> =
  Symbol();
