<template>
  <div
    :class="{ 'pb-16': feedIsErrored, disabled: feedIsDeleting }"
    class="c-google-sheets-feed c-feed-list__feed"
  >
    <div :class="itemHeaderClass">
      <ExpandButton
        v-if="sources.length > 0"
        :toggle="toggleRowsCollapsed"
        :mode="rowsCollapsed ? 'collapsed' : 'expanded'"
      />
      <slot name="left-content" :multiple-sources="sources.length > 0" />
      <div v-if="feedStatus" class="flex items-center gap-10">
        <slot
          name="status"
          :icon-class="connectionStatusColor"
          :text="connectionStatusText"
        />
        <BittsButton
          v-if="hasPermission('manage:data-sources')"
          type="neutral"
          variant="outline"
          size="x-small"
          text="Add"
          :disabled="feedAddSourceDisabled"
          @click="showGoogleSheetsModal"
        />
        <Sync :feed="feed" />
      </div>
    </div>
    <slot v-if="feedDeletionFailed" name="feed-deletion-failed" />
    <slot v-if="feedIsErrored" name="feed-error" />
    <FeedListItemRow
      v-for="(source, index) in sources"
      :key="`feed_table_${index}`"
      :class="{ hidden: rowsCollapsed }"
      :source="source"
      :feed-status="feedStatus"
      :feed="feed"
    />
  </div>
</template>

<script>
import { BittsButton } from '@crossbeam/bitts';

import { computed, ref } from 'vue';

import FeedListItemRow from '@/components/data-sources/FeedListItemRow.vue';
import Sync from '@/components/data-sources/Sync.vue';
import ExpandButton from '@/components/ExpandButton.vue';

import useAuth from '@/composables/useAuth';
import useFeedDetails from '@/composables/useFeedDetails';
import useFeedSyncing from '@/composables/useFeedSyncing';
import { SOURCE_STATUS_MAP } from '@/constants/data_sources';
import { useSourcesStore } from '@/stores';
import { sortByKey } from '@/utils';

const DANGER = 'danger';
const WARNING = 'warning';

export default {
  name: 'GoogleSheetsFeed',
  components: {
    FeedListItemRow,
    ExpandButton,
    BittsButton,
    Sync,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sourcesStore = useSourcesStore();
    const {
      feedStatus,
      feedIsErrored,
      feedIsActive,
      feedIsPaused,
      feedIsWarning,
      feedIsDeleting,
      feedDeletionFailed,
      feedAddSourceDisabled,
    } = useFeedDetails({ feedId: props.feed.id });

    const { hasPermission } = useAuth();

    /* Will poll the feed if it's syncing. This will refresh the store
    and update the feed's status */
    useFeedSyncing({ feed: props.feed });

    const sources = computed(() =>
      sourcesStore
        .getSourcesByFeedId(props.feed.id)
        .filter((source) => source.is_base_table)
        .sort(sortByKey('created_at', 'desc', 'timestamp')),
    );

    /* Local component state */
    const rowsCollapsed = ref(true);

    return {
      feedIsDeleting,
      feedDeletionFailed,
      feedStatus,
      feedIsPaused,
      feedIsErrored,
      feedIsWarning,
      feedIsActive,
      feedAddSourceDisabled,
      rowsCollapsed,
      sources,
      hasPermission,
    };
  },
  computed: {
    hasSourceError() {
      /* For Google Sheets specifically, we want to
      show an error on the feed if any of the sources are errored.
      Errors on sources supercede errors on feeds and cause the
      feed to be in an error state. */

      /* The missing_required property isn't counted as an error. During a refactor
      of the API this should all be simplified + changed */
      return this.sources.some((source) => {
        return SOURCE_STATUS_MAP.error.includes(source.status);
      });
    },
    hasSourceWarning() {
      /* Missing required cells is a warning */
      return this.sources.some((source) => {
        return (
          source?.properties?.row_errors?.missing_required > 0 ||
          SOURCE_STATUS_MAP.warning.includes(source.status)
        );
      });
    },
    hasSourceProcessing() {
      return this.sources.some((source) => {
        return SOURCE_STATUS_MAP.process.includes(source.status);
      });
    },
    worstFeedStatus() {
      if (this.feedIsErrored || this.feedIsDeleting) return DANGER;
      if (this.feedIsWarning || this.feedDeletionFailed || this.feedIsPaused)
        return WARNING;
      return null;
    },
    worstSourceStatus() {
      if (this.hasSourceError) return DANGER;
      if (this.hasSourceWarning) return WARNING;
      return null;
    },
    /* The reason that this is so confusing is because we're trying to
      jam multiple values into a single status right now. We cannot display whether something
      is paused and errored, for instance, even though that's technically possible. So,
      we have to prioritize certain types of errors/warnings */
    connectionStatusText() {
      if (this.worstFeedStatus) {
        return this.worstSourceStatus === DANGER &&
          this.worstFeedStatus === WARNING
          ? 'Error'
          : this.feedStatus.text;
      }

      if (this.worstSourceStatus === DANGER) return 'Error';
      if (this.worstSourceStatus === WARNING) return 'Warning';
      if (this.hasSourceProcessing) return 'Setting up...';

      return 'Connection Active';
    },
    connectionStatusColor() {
      if ([this.worstFeedStatus, this.worstSourceStatus].includes(DANGER))
        return 'bg-danger-accent';
      if ([this.worstFeedStatus, this.worstSourceStatus].includes(WARNING))
        return 'bg-warning-accent';
      return 'bg-success-accent';
    },
    itemHeaderClass() {
      const base = ['flex justify-between p-16'];
      if (!this.sources.length) base.push('ml-28');
      if (this.feedIsErrored) base.push('pb-0');
      return base;
    },
  },
  methods: {
    showGoogleSheetsModal() {
      this.$router.push({ name: 'google-sheets' });
    },
    toggleRowsCollapsed() {
      this.rowsCollapsed = !this.rowsCollapsed;
    },
  },
};
</script>
