import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { groupBy, mapValues, sortBy, xor } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { crossbeamApi } from '@/api';
import { MDM_TYPES, MdmType } from '@/constants/mdm';
import {
  useDataSharePresetsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';
import { DataSharePreset } from '@/types/data_share_presets';

export function useFieldPresets({
  feedId,
  sourceId,
  presetId,
  cancelDestination,
  isSingleShare = false,
}: {
  feedId: number;
  sourceId?: number;
  presetId?: number;
  cancelDestination?: string;
  isSingleShare?: boolean;
}) {
  const router = useRouter();
  const route = useRoute();

  const { getSourcesByFeedId, getSourceAndFieldByFieldId } = useSourcesStore();
  const { addSuccessFlash, addErrorFlash } = useFlashesStore();
  const dataSharePresetsStore = useDataSharePresetsStore();

  const { ready } = storeToRefs(dataSharePresetsStore);

  /**
   * FIELDS LOGIC
   */

  const presetName = ref<string>('');
  const defaultPresetId = ref<number>();
  const allSourceFieldIds = ref<number[]>([]);
  const isToggledNewPreset = ref<boolean>(false);

  const isDefaultPreset = computed(() => defaultPresetId.value === presetId);
  const sortedPresets = computed(() => sortNamedPresets(feedId, sourceId));
  const currentSourceFieldIds = computed(() =>
    Object.values(sourceFieldIdsByMdm.value).flatMap((item) => item.ids.value),
  );

  const defaultPresetSourceFieldIds = computed(() =>
    dataSharePresetsStore.getSourceFieldIdsByPresetId(
      feedId,
      sourceId,
      defaultPresetId.value,
    ),
  );
  const hasDefaultPresets = computed(() => {
    const differentIdList = xor(
      defaultPresetSourceFieldIds.value,
      currentSourceFieldIds.value,
    );
    return differentIdList.length === 0;
  });

  const sourceFieldIdsByMdm = computed(() => {
    // Object sorting the source field ids by their mdm name
    const groupedWithTable = groupBy(
      allSourceFieldIds.value,
      (sourceFieldId) => getMdmInfoFromSourceFieldId(sourceFieldId).table,
    );
    // Adding mdm_type and making the list of ids reactive
    const groupedWithTableAndType = mapValues(groupedWithTable, (ids) => ({
      mdm_type: getMdmInfoFromSourceFieldId(ids[0] as number).mdm_type,
      ids: ref(ids),
    }));
    return groupedWithTableAndType;
  });

  function resetToDefault() {
    allSourceFieldIds.value = [...defaultPresetSourceFieldIds.value];
  }

  function getFieldOptions(mdmType: MdmType) {
    const sourcesByFeedId = getSourcesByFeedId(feedId);
    const source = sourceId
      ? sourcesByFeedId?.find((source) => source.id === sourceId) // Get specific csv/gsheet source
      : sourcesByFeedId?.find((source) => source.mdm_type === mdmType); // Get CRM source
    const fields = source?.fields.filter((f) => f.is_filterable);

    const fieldOptions = fields?.map((field) => ({
      label: field.nickname,
      value: field.id,
    }));
    return fieldOptions;
  }

  onMounted(async () => {
    if (!cancelDestination) return; // Not running it for FieldPresetTab

    // Running for the FieldPresetsModal
    await dataSharePresetsStore.readySync;
    defaultPresetId.value = dataSharePresetsStore.getDefaultPresetId(
      feedId,
      sourceId,
    );
    allSourceFieldIds.value = dataSharePresetsStore.getSourceFieldIdsByPresetId(
      feedId,
      sourceId,
      presetId || defaultPresetId.value,
    );
    presetName.value = dataSharePresetsStore.getPresetName(
      feedId,
      sourceId,
      presetId || defaultPresetId.value,
      (route.name as string).includes('create-preset'),
    );
  });

  /**
   * MODAL LOGIC
   */

  function openModal(id?: number) {
    id
      ? router.push({
          name: 'edit-preset',
          params: { preset_id: id },
        })
      : router.push({
          name: 'create-preset',
        });
  }

  async function closeModal() {
    await router.push(cancelDestination as string);
  }

  /**
   * API METHODS
   */

  // CREATE PRESET
  async function createPreset(name: string, fieldIds: number[]) {
    const isSingleShareWithoutName = isSingleShare && !isToggledNewPreset.value;
    const body: GetApiPayloadByUrl<'/v0.1/data-share-presets', 'post'> = {
      name: isSingleShareWithoutName ? null : name,
      preset_type: isSingleShareWithoutName
        ? 'single_share_preset'
        : 'named_preset',
      feed_id: feedId,
      base_source_id: sourceId || null, // has only a number if csv or gsheet
      source_field_ids: fieldIds,
    };

    const { data, error } = await crossbeamApi.POST(
      '/v0.1/data-share-presets',
      { body },
    );

    handleErrorAndSuccess(
      `An error occurred while trying to create ${name} preset`,
      error,
      () => redirectAndSuccess(name, data, true),
    );
  }

  // PATCH PRESET
  async function editPreset(id: number, name: string, fieldIds: number[]) {
    const body: GetApiPayloadByUrl<'/v0.1/data-share-presets/{id}', 'patch'> = {
      name,
      source_field_ids: fieldIds,
    };

    const { data, error } = await crossbeamApi.PATCH(
      '/v0.1/data-share-presets/{id}',
      { body, params: { path: { id } } },
    );

    handleErrorAndSuccess(
      `An error occurred while trying to edit ${name} preset`,
      error,
      () => redirectAndSuccess(name, data),
    );
  }

  // DELETE PRESET
  async function deletePreset(id: number, name: string) {
    const { data, error } = await crossbeamApi.DELETE(
      '/v0.1/data-share-presets/{id}',
      { params: { path: { id } } },
    );

    handleErrorAndSuccess(
      `An error occurred while trying to delete ${name} preset`,
      error,
      () => handleSuccess(data, `${name} preset has been successfully deleted`),
    );
  }

  /**
   * HELPERS
   */

  function getMdmInfoFromSourceFieldId(sourceFieldId: number) {
    const data = getSourceAndFieldByFieldId(sourceFieldId);
    return {
      mdm_type: data?.source.mdm_type ?? MDM_TYPES.ACCOUNT,
      table: data?.source.table,
    };
  }

  function sortNamedPresets(feedId: number, sourceId?: number) {
    const filteredPresets = dataSharePresetsStore
      .getFilteredPresetsByFeeId(feedId, sourceId)
      .map((preset) => ({
        ...preset,
        name:
          preset.preset_type === 'crossbeam_default'
            ? 'Crossbeam default preset'
            : preset.name,
      }));

    const filteredNamedPresets = filteredPresets.filter(
      (preset) => preset.preset_type !== 'single_share_preset',
    );

    return sortBy(filteredNamedPresets, [
      (preset) => preset.preset_type !== 'crossbeam_default', // Ensures crossbeam_default is first
      (preset) => preset.name?.toLowerCase() ?? '', // Sort the rest alphabetically
    ]);
  }

  async function redirectAndSuccess(
    name: string,
    data?: DataSharePreset,
    isNewPreset?: boolean,
  ) {
    const routeName = router.currentRoute.value.name;
    const currentQuery = router.currentRoute.value.query;
    const presetParam = isNewPreset ? 'newPresetId' : 'presetId';

    const isFromPartnerSettings =
      currentQuery.returnTo === 'settings' &&
      Boolean(currentQuery.populationId);

    const inviteRoutes = [
      'onboarding-invite-sharing-create-preset',
      'onboarding-invite-sharing-preset-details',
      'partners-invite-sharing-create-preset',
      'partners-invite-sharing-preset-details',
    ];

    const isFromInvite = inviteRoutes.includes(routeName as string);

    // adding presetId to the query to update the selected preset
    if (isFromInvite || isFromPartnerSettings) {
      const query = {
        ...(isFromInvite ? route.query : {}),
        populationId: currentQuery.populationId,
        [presetParam]: data?.id,
      };

      await router.replace({
        path: cancelDestination,
        query,
      });
    }

    handleSuccess(
      data,
      `${name} preset has been successfully ${isNewPreset ? 'created' : 'edited'}`,
      isFromInvite || isFromPartnerSettings,
    );
  }

  async function handleSuccess(
    data?: DataSharePreset,
    message?: string,
    alreadyClosed?: boolean,
  ) {
    if (data) await dataSharePresetsStore.refreshDataSharePresetsStore();
    if (message) addSuccessFlash({ message });
    if (!alreadyClosed) closeModal();
  }

  function handleErrorAndSuccess(
    errorMessage: string,
    error?: { errors: string[] },
    onSuccess?: () => void,
  ) {
    if (error) {
      addErrorFlash({
        message: error.errors?.[0] || errorMessage,
      });
      return;
    }
    onSuccess?.();
  }

  return {
    presetName,
    sortedPresets,
    isDefaultPreset,
    hasDefaultPresets,
    sourceFieldIdsByMdm,
    currentSourceFieldIds,
    isToggledNewPreset,
    isLoading: !ready,
    openModal,
    closeModal,
    createPreset,
    editPreset,
    deletePreset,
    getFieldOptions,
    resetToDefault,
  };
}
