import { useStorage } from '@vueuse/core';
import { DateTime } from 'luxon';
import { computed, watch } from 'vue';
import { LocationQueryValue, useRoute, useRouter } from 'vue-router';

import useAuth from './useAuth';

const VALID_VALUES = {
  coverageType: ['partners', 'engagement'],
  opportunityType: ['all', 'net-new', 'existing'],
  timePeriod: ['thisQuarter', 'lastQuarter', 'yearToDate'],
} as const;

export type CoverageType = (typeof VALID_VALUES.coverageType)[number];
export type OpportunityType = (typeof VALID_VALUES.opportunityType)[number];
export type TimePeriod = (typeof VALID_VALUES.timePeriod)[number];

export type PerformanceDashboardFilters = {
  [K in keyof typeof VALID_VALUES]: (typeof VALID_VALUES)[K][number];
};

export const defaultFilters: PerformanceDashboardFilters = {
  coverageType: 'partners',
  opportunityType: 'all',
  timePeriod: 'thisQuarter',
};

export function usePerformanceDashboardFilters() {
  const route = useRoute();
  const router = useRouter();
  const { currentOrg } = useAuth();

  const fiscalYearStart = computed(
    () => currentOrg.value?.fiscal_year_start ?? 1,
  );

  const filters = useStorage<PerformanceDashboardFilters>(
    'performanceDashboardFilters',
    defaultFilters,
    sessionStorage,
    { mergeDefaults: true },
  );

  const updateFilters = (newFilters: Partial<PerformanceDashboardFilters>) => {
    filters.value = {
      ...filters.value,
      ...newFilters,
    };
  };

  const syncFromQuery = () => {
    if (route.path !== '/measure/ecosystem') return;

    const updated = {} as Record<keyof PerformanceDashboardFilters, string>;
    let needsUrlFix = false;

    (
      Object.keys(defaultFilters) as (keyof PerformanceDashboardFilters)[]
    ).forEach((key) => {
      const queryValue = route.query[key]?.toString();
      const values = VALID_VALUES[key] as readonly string[];
      const isValid = !!queryValue && values.includes(queryValue);

      if (isValid) {
        filters.value = {
          ...filters.value,
          [key]: queryValue,
        };
        updated[key] = queryValue as PerformanceDashboardFilters[typeof key];
      } else {
        const fallback = filters.value[key] ?? defaultFilters[key];
        updated[key] = fallback;
        filters.value = {
          ...filters.value,
          [key]: fallback,
        };
        needsUrlFix = true;
      }
    });

    if (needsUrlFix || Object.keys(route.query).length === 0) {
      router.replace({ query: updated });
    }
  };

  syncFromQuery();

  watch(
    filters,
    (newVal) => {
      const query: Record<string, LocationQueryValue | LocationQueryValue[]> = {
        ...route.query,
      };
      let changed = false;

      (Object.keys(newVal) as (keyof PerformanceDashboardFilters)[]).forEach(
        (key) => {
          if (query[key] !== newVal[key]) {
            query[key] = newVal[key];
            changed = true;
          }
        },
      );

      if (changed) {
        router.replace({ query });
      }
    },
    { deep: true },
  );

  const formattedDates = computed(() => {
    const now = DateTime.local();
    let start: DateTime;
    let end: DateTime | undefined = undefined;
    const fiscalYearStartVal = fiscalYearStart.value;

    switch (filters.value.timePeriod) {
      case 'thisQuarter': {
        const quarterStartMonth =
          Math.floor((now.month - fiscalYearStartVal) / 3) * 3 +
          fiscalYearStartVal;
        start = DateTime.local()
          .set({ month: quarterStartMonth })
          .startOf('month');
        break;
      }
      case 'lastQuarter': {
        const lastQuarterStartMonth =
          Math.floor((now.month - fiscalYearStartVal - 3) / 3) * 3 +
          fiscalYearStartVal;
        start = DateTime.local()
          .set({ month: lastQuarterStartMonth })
          .startOf('month');
        end = start.plus({ months: 3 }).minus({ days: 1 });
        break;
      }
      case 'yearToDate': {
        const fiscalStart = DateTime.local()
          .set({ month: fiscalYearStartVal })
          .startOf('month');
        start =
          fiscalStart > now ? fiscalStart.minus({ years: 1 }) : fiscalStart;
        break;
      }
      default: {
        start = now;
        end = now;
      }
    }

    return {
      startDate: start.toFormat('yyyy-MM'),
      endDate: end?.toFormat('yyyy-MM'),
    };
  });

  return { filters, updateFilters, formattedDates };
}
