import { DateTime } from 'luxon';

/* Given a Luxon DateTime, return a human readable string of how recent the Luxon date was. */
export const todayYesterdayTomorrowOrCustom = (
  eventDate: DateTime,
  withTime: boolean,
  formatCallback: (date: DateTime) => string,
) => {
  if (!eventDate.isValid) return 'No date';
  let result;
  const today = DateTime.local();
  const happenedToday = eventDate.hasSame(today, 'day');
  const happenedYesterday = eventDate.hasSame(today.plus({ days: -1 }), 'day');
  const happenedTomorrow = eventDate.hasSame(today.plus({ days: 1 }), 'day');
  if (happenedToday) {
    result = withTime ? `Today at ${eventDate.toFormat('t')}` : 'Today';
  } else if (happenedYesterday) {
    result = withTime ? `Yesterday at ${eventDate.toFormat('t')}` : 'Yesterday';
  } else if (happenedTomorrow) {
    result = withTime ? `Tomorrow at ${eventDate.toFormat('t')}` : 'Tomorrow';
  } else {
    result = formatCallback(eventDate);
  }
  return result;
};

const makePositive = (unit: number) => Math.abs(Math.ceil(unit));

/* Returns an approximate time in seconds, minutes, hours, or days since the provided Luxon date */
export const timeSince = (eventDate: DateTime) => {
  if (!eventDate.isValid) return '';
  const diff = eventDate.diffNow(['seconds', 'minutes', 'hours', 'days']);
  const ms = Math.abs(diff.valueOf());
  if (ms < 5000) return 'a few seconds ago';
  if (ms < 60000) {
    const seconds = makePositive(diff.seconds);
    return `${seconds} seconds ago`;
  }
  if (ms < 3600000) {
    const minutes = makePositive(diff.minutes);
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  }
  if (ms < 86400000) {
    const hours = makePositive(diff.hours);
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  }

  const days = makePositive(diff.days);
  return days === 1 ? '1 day ago' : `${days} days ago`;
};

/* Takes a number and turns it into an ordinal date, e.g. 9th, 2nd, etc */
export function ordinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'] as const;
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const formatAmount = (
  value?: number | unknown,
  params?: Intl.NumberFormatOptions,
) => {
  if (typeof value !== 'number') return '$--';
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...params,
  };
  const amount = new Intl.NumberFormat('en-US', options);
  return amount.format(value);
};

export const formatAmountCompact = (value?: number | unknown) =>
  formatAmount(value, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  });
