import { computed } from 'vue';

import { ADMIN_PANEL } from '@/constants/team';
import { useRootStore } from '@/stores/RootStore';

export default function useAuth() {
  const store = useRootStore();
  const currentAuth = computed(() => store.currentAuth);
  const currentOrg = computed(() => store.currentOrg);
  return {
    hasScope: (scope: string) => store.hasScope(scope),
    hasPermission: (permission: string) => store.hasPermission(permission),
    resourcePermission: (resource: string) =>
      store.resourcePermission(resource),
    currentAuth,
    isAdminPanelUser: computed(
      () => currentAuth.value?.authorizer_type === ADMIN_PANEL,
    ),
    isSalesOnlyUser: computed(
      () => currentAuth.value?.role?.seat_type === 'sales',
    ),
    authorizations: computed(() => store.authorizations),
    isLoggedIn: computed(() => store.isLoggedIn),
    currentUser: computed(() => store.currentUser),
    currentOrg,
    migratedFromReveal: computed(() =>
      'migrated_from_reveal' in currentOrg.value
        ? currentOrg.value?.migrated_from_reveal
        : undefined,
    ),
    userCanTryToLinkAccounts: computed(() => store.userCanTryToLinkAccounts),
    initializationFailed: computed(() => store.initializationFailed),
    isAdmin: computed(() => currentAuth.value?.role.name === 'Admin'),
    inMaintenanceMode: computed(() => !!store.maintenanceModeEvent),
    preferredCRM: computed(() => store.currentOrg?.preferred_crm),
    preferredCRMFeedId: computed(() => store.currentOrg?.preferred_crm_feed_id),
  };
}
